<template>
    <div class="index">
        <!-- 头部通用 -->
        <Header />

        <!-- 内容区域 -->
        <div class="content">
            <div class="main">
                <!-- 首页图片 -->
                <div class="toppic" v-for="item in toppicList" :key="item.id">
                    <a :href="item.link" target="_blank">
                        <img :src="item.litpic" />
                    </a>
                </div>

                <!-- 顶部新闻 -->
                <div class="topnews">
                    <!-- 头条 -->
                    <div class="title">
                        <router-link
                            target="_blank"
                            :to="{
                                name: 'article',
                                params: { id: ttList.id },
                            }"
                        >
                            {{ ttList.title }}
                        </router-link>
                    </div>
                    <div class="line"><i></i><em></em></div>
                    <!-- 要闻 -->
                    <div class="list">
                        <ul>
                            <li v-for="item in ttList2" :key="item.id">
                                <router-link
                                    target="_blank"
                                    :to="{
                                        name: 'article',
                                        params: { id: item.id },
                                    }"
                                >
                                    {{ item.title }}
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- 书记、县长专题 -->
                <!-- <div class="lingdao">
                    <div class="left">
                        <div class="title">
                            <h3>报道集</h3>
                        </div>
                        <div class="list">
                            <router-link
                                :to="{
                                    path: '/article/list/5680f4a3-fbf7-4b38-95b3-5d89a7a12712',
                                }"
                            >
                                <img src="../assets/shuji.png" />
                            </router-link>
                            <router-link
                                :to="{
                                    path: '/article/list/d02bb3d1-bb90-4ce1-9c38-98a3b9dd96cc',
                                }"
                            >
                                <img src="../assets/xianzhang.jpg" />
                            </router-link>
                        </div>
                    </div>
                    <div class="right">
                        <img src="../assets/app.png" />
                    </div>
                </div> -->

                <div class="news">
                    <div class="newsleft">
                        <!-- 时政 -->
                        <div class="newsbox">
                            <div class="title">
                                <i><img src="../assets/icon-sz.png" /></i>
                                <router-link
                                    :to="{
                                        name: 'list',
                                        params: {
                                            id: '5680f4a3-fbf7-4b38-95b3-5d89a7a12712',
                                        },
                                    }"
                                >
                                    更多>
                                </router-link>
                            </div>
                            <div class="txtnews">
                                <ul>
                                    <li v-for="item in szList" :key="item.id">
                                        <router-link
                                            target="_blank"
                                            :to="{
                                                name: 'article',
                                                params: { id: item.id },
                                            }"
                                        >
                                            {{ item.title }}
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <!-- 拍客 -->
                        <div class="newsbox">
                            <div class="title">
                                <i><img src="../assets/icon-pk.png" /></i>
                                <router-link
                                    :to="{
                                        name: 'list',
                                        params: {
                                            id: '010ae2d0-c65c-4b49-ad4e-4f499959b01e',
                                        },
                                    }"
                                >
                                    更多>
                                </router-link>
                            </div>
                            <div class="picnews">
                                <ul>
                                    <li v-for="item in pkList" :key="item.id">
                                        <router-link
                                            target="_blank"
                                            :to="{
                                                name: 'article',
                                                params: { id: item.id },
                                            }"
                                        >
                                            <i><img :src="item.litpic" /></i>
                                            <h3>{{ item.title }}</h3>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <!-- 媒体聚焦 -->
                        <div class="newsbox">
                            <div class="title">
                                <i><img src="../assets/icon-mtjj.png" /></i>
                                <router-link
                                    :to="{
                                        name: 'list',
                                        params: {
                                            id: '0feea206-1bf0-4651-9952-eeacb0c14028',
                                        },
                                    }"
                                >
                                    更多>
                                </router-link>
                            </div>
                            <div class="txtnews">
                                <ul>
                                    <li v-for="item in mtjjList" :key="item.id">
                                        <router-link
                                            target="_blank"
                                            :to="{
                                                name: 'article',
                                                params: { id: item.id },
                                            }"
                                        >
                                            {{ item.title }}
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="newsright">
                        <!-- 本地 -->
                        <div class="newsbox">
                            <div class="title">
                                <i><img src="../assets/icon-bd.png" /></i>
                                <router-link
                                    :to="{
                                        name: 'list',
                                        params: {
                                            id: '5cf0b652-27e2-4648-8fab-16bcfefdcb32',
                                        },
                                    }"
                                >
                                    更多>
                                </router-link>
                            </div>
                            <div class="txtnews">
                                <ul>
                                    <li v-for="item in bdList" :key="item.id">
                                        <router-link
                                            target="_blank"
                                            :to="{
                                                name: 'article',
                                                params: { id: item.id },
                                            }"
                                        >
                                            {{ item.title }}
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <!-- 直播 -->
                        <div class="newsbox">
                            <div class="title">
                                <i><img src="../assets/icon-zb.png" /></i>
                                <router-link
                                    :to="{
                                        name: 'list',
                                        params: {
                                            id: '92396983-ebb3-47aa-a958-db1381420b73',
                                        },
                                    }"
                                >
                                    更多>
                                </router-link>
                            </div>
                            <div class="picnews">
                                <ul>
                                    <li v-for="item in zbList" :key="item.id">
                                        <router-link
                                            target="_blank"
                                            :to="{
                                                name: 'article',
                                                params: { id: item.id },
                                            }"
                                        >
                                            <i><img :src="item.litpic" /></i>
                                            <h3>{{ item.title }}</h3>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <!-- 长视新闻 -->
                        <div class="newsbox">
                            <div class="title">
                                <i><img src="../assets/icon-csxw.png" /></i>
                                <router-link
                                    :to="{
                                        name: 'list',
                                        params: {
                                            id: '408c38bf-a2b1-4baa-b432-ea369adf8dd2',
                                        },
                                    }"
                                >
                                    更多>
                                </router-link>
                            </div>
                            <div class="picnews">
                                <ul>
                                    <li v-for="item in csxwList" :key="item.id">
                                        <router-link
                                            target="_blank"
                                            :to="{
                                                name: 'article',
                                                params: { id: item.id },
                                            }"
                                        >
                                            <i><img :src="item.litpic" /></i>
                                            <h3>{{ item.title }}</h3>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 底部通用 -->
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
export default {
    components: { Header, Footer },
    metaInfo() {
        return {
            title: process.env.VUE_APP_TITLE,
            meta: [
                {
                    name: 'keywords',
                    content: process.env.VUE_APP_KEYWORDS,
                },
                {
                    name: 'description',
                    content: process.env.VUE_APP_DESCRIPTION,
                },
            ],
        }
    },
    data() {
        return {
            toppicList: [],
            ttList: {
                id: 0,
                title: '',
            },
            ttList2: [],
            szList: [],
            csxwList: [],
            mtjjList: [],
            pkList: [],
            zbList: [],
            bdList: [],
        }
    },
    created() {
        this.getToppicList() // 顶部通栏
        this.ttListArticle() // 头条
        this.szListArticle() // 时政
        this.bdListArticle() // 本地
        this.csxwListArticle() // 长视新闻
        this.zbListArticle() // 直播
        this.mtjjListArticle() // 媒体聚焦
        this.pkListArticle() // 拍客
    },
    methods: {
        getToppicList() {
            this.$api.toppic_index().then((res) => {
                // console.log(res)
                this.toppicList = res
            })
        },
        ttListArticle() {
            this.$api
                .article_index({
                    channel_id: '64454068-9d43-4281-8aae-816360e03cd7',
                    limit: 6,
                })
                .then((res) => {
                    res.forEach((element, index) => {
                        if (index == 0) {
                            this.ttList = element
                        } else {
                            this.ttList2.push(element)
                        }
                    })
                })
        },
        szListArticle() {
            // this.$api
            //     .article_shizheng({
            //         limit: 5,
            //     })
            //     .then((res) => {
            //         this.szList = res
            //     })
            this.$api
                .article_index({
                    channel_id: '5680f4a3-fbf7-4b38-95b3-5d89a7a12712',
                    limit: 5,
                })
                .then((res) => {
                    this.szList = res
                })
        },
        bdListArticle() {
            this.$api
                .article_index({
                    channel_id: '5cf0b652-27e2-4648-8fab-16bcfefdcb32',
                    limit: 5,
                })
                .then((res) => {
                    this.bdList = res
                })
        },
        csxwListArticle() {
            this.$api
                .article_index({
                    channel_id: '408c38bf-a2b1-4baa-b432-ea369adf8dd2',
                    limit: 6,
                })
                .then((res) => {
                    this.csxwList = res
                })
        },
        pkListArticle() {
            this.$api
                .article_index({
                    channel_id: '010ae2d0-c65c-4b49-ad4e-4f499959b01e',
                    limit: 2,
                })
                .then((res) => {
                    this.pkList = res
                })
        },
        mtjjListArticle() {
            this.$api
                .article_index({
                    channel_id: '0feea206-1bf0-4651-9952-eeacb0c14028',
                    limit: 14,
                })
                .then((res) => {
                    this.mtjjList = res
                })
        },
        zbListArticle() {
            this.$api
                .article_index({
                    channel_id: '0feea206-1bf0-4651-9952-eeacb0c14028',
                    limit: 2,
                })
                .then((res) => {
                    this.zbList = res
                })
        },
    },
}
</script>
