var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"main"},[_vm._m(0),_c('div',{staticClass:"nav"},[_c('ul',{staticClass:"nav_ul"},[_c('li',{staticClass:"nav_li"},[_c('router-link',{attrs:{"to":{ path: '/' }}},[_vm._v("首页")])],1),_c('li',{staticClass:"nav_li"},[_c('router-link',{attrs:{"to":{
                            path: '/article/list/64454068-9d43-4281-8aae-816360e03cd7',
                        }}},[_vm._v(" 要闻 ")])],1),_c('li',{staticClass:"nav_li"},[_c('router-link',{attrs:{"to":{
                            path: '/article/list/5cf0b652-27e2-4648-8fab-16bcfefdcb32',
                        }}},[_vm._v(" 本地 ")])],1),_c('li',{staticClass:"nav_li"},[_c('router-link',{attrs:{"to":{
                            path: '/article/list/92396983-ebb3-47aa-a958-db1381420b73',
                        }}},[_vm._v(" 直播 ")])],1),_c('li',{staticClass:"nav_li"},[_c('router-link',{attrs:{"to":{
                            path: '/article/list/010ae2d0-c65c-4b49-ad4e-4f499959b01e',
                        }}},[_vm._v(" 拍客 ")])],1),_c('li',{staticClass:"nav_li"},[_c('router-link',{attrs:{"to":{
                            path: '/article/list/408c38bf-a2b1-4baa-b432-ea369adf8dd2',
                        }}},[_vm._v(" 长视 ")])],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../../assets/logo.png")}})])}]

export { render, staticRenderFns }