var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"index"},[_c('Header'),_c('div',{staticClass:"content"},[_c('div',{staticClass:"main"},[_vm._l((_vm.toppicList),function(item){return _c('div',{key:item.id,staticClass:"toppic"},[_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_c('img',{attrs:{"src":item.litpic}})])])}),_c('div',{staticClass:"topnews"},[_c('div',{staticClass:"title"},[_c('router-link',{attrs:{"target":"_blank","to":{
                            name: 'article',
                            params: { id: _vm.ttList.id },
                        }}},[_vm._v(" "+_vm._s(_vm.ttList.title)+" ")])],1),_vm._m(0),_c('div',{staticClass:"list"},[_c('ul',_vm._l((_vm.ttList2),function(item){return _c('li',{key:item.id},[_c('router-link',{attrs:{"target":"_blank","to":{
                                    name: 'article',
                                    params: { id: item.id },
                                }}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),0)])]),_c('div',{staticClass:"news"},[_c('div',{staticClass:"newsleft"},[_c('div',{staticClass:"newsbox"},[_c('div',{staticClass:"title"},[_vm._m(1),_c('router-link',{attrs:{"to":{
                                    name: 'list',
                                    params: {
                                        id: '5680f4a3-fbf7-4b38-95b3-5d89a7a12712',
                                    },
                                }}},[_vm._v(" 更多> ")])],1),_c('div',{staticClass:"txtnews"},[_c('ul',_vm._l((_vm.szList),function(item){return _c('li',{key:item.id},[_c('router-link',{attrs:{"target":"_blank","to":{
                                            name: 'article',
                                            params: { id: item.id },
                                        }}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),0)])]),_c('div',{staticClass:"newsbox"},[_c('div',{staticClass:"title"},[_vm._m(2),_c('router-link',{attrs:{"to":{
                                    name: 'list',
                                    params: {
                                        id: '010ae2d0-c65c-4b49-ad4e-4f499959b01e',
                                    },
                                }}},[_vm._v(" 更多> ")])],1),_c('div',{staticClass:"picnews"},[_c('ul',_vm._l((_vm.pkList),function(item){return _c('li',{key:item.id},[_c('router-link',{attrs:{"target":"_blank","to":{
                                            name: 'article',
                                            params: { id: item.id },
                                        }}},[_c('i',[_c('img',{attrs:{"src":item.litpic}})]),_c('h3',[_vm._v(_vm._s(item.title))])])],1)}),0)])]),_c('div',{staticClass:"newsbox"},[_c('div',{staticClass:"title"},[_vm._m(3),_c('router-link',{attrs:{"to":{
                                    name: 'list',
                                    params: {
                                        id: '0feea206-1bf0-4651-9952-eeacb0c14028',
                                    },
                                }}},[_vm._v(" 更多> ")])],1),_c('div',{staticClass:"txtnews"},[_c('ul',_vm._l((_vm.mtjjList),function(item){return _c('li',{key:item.id},[_c('router-link',{attrs:{"target":"_blank","to":{
                                            name: 'article',
                                            params: { id: item.id },
                                        }}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),0)])])]),_c('div',{staticClass:"newsright"},[_c('div',{staticClass:"newsbox"},[_c('div',{staticClass:"title"},[_vm._m(4),_c('router-link',{attrs:{"to":{
                                    name: 'list',
                                    params: {
                                        id: '5cf0b652-27e2-4648-8fab-16bcfefdcb32',
                                    },
                                }}},[_vm._v(" 更多> ")])],1),_c('div',{staticClass:"txtnews"},[_c('ul',_vm._l((_vm.bdList),function(item){return _c('li',{key:item.id},[_c('router-link',{attrs:{"target":"_blank","to":{
                                            name: 'article',
                                            params: { id: item.id },
                                        }}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),0)])]),_c('div',{staticClass:"newsbox"},[_c('div',{staticClass:"title"},[_vm._m(5),_c('router-link',{attrs:{"to":{
                                    name: 'list',
                                    params: {
                                        id: '92396983-ebb3-47aa-a958-db1381420b73',
                                    },
                                }}},[_vm._v(" 更多> ")])],1),_c('div',{staticClass:"picnews"},[_c('ul',_vm._l((_vm.zbList),function(item){return _c('li',{key:item.id},[_c('router-link',{attrs:{"target":"_blank","to":{
                                            name: 'article',
                                            params: { id: item.id },
                                        }}},[_c('i',[_c('img',{attrs:{"src":item.litpic}})]),_c('h3',[_vm._v(_vm._s(item.title))])])],1)}),0)])]),_c('div',{staticClass:"newsbox"},[_c('div',{staticClass:"title"},[_vm._m(6),_c('router-link',{attrs:{"to":{
                                    name: 'list',
                                    params: {
                                        id: '408c38bf-a2b1-4baa-b432-ea369adf8dd2',
                                    },
                                }}},[_vm._v(" 更多> ")])],1),_c('div',{staticClass:"picnews"},[_c('ul',_vm._l((_vm.csxwList),function(item){return _c('li',{key:item.id},[_c('router-link',{attrs:{"target":"_blank","to":{
                                            name: 'article',
                                            params: { id: item.id },
                                        }}},[_c('i',[_c('img',{attrs:{"src":item.litpic}})]),_c('h3',[_vm._v(_vm._s(item.title))])])],1)}),0)])])])])],2)]),_c('Footer')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"line"},[_c('i'),_c('em')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('i',[_c('img',{attrs:{"src":require("../assets/icon-sz.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('i',[_c('img',{attrs:{"src":require("../assets/icon-pk.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('i',[_c('img',{attrs:{"src":require("../assets/icon-mtjj.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('i',[_c('img',{attrs:{"src":require("../assets/icon-bd.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('i',[_c('img',{attrs:{"src":require("../assets/icon-zb.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('i',[_c('img',{attrs:{"src":require("../assets/icon-csxw.png")}})])}]

export { render, staticRenderFns }